import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'

import {p} from './css/mobile.module.css'

const dataQuery = graphql`
  query {
    content: homepageJson {
      sections {
        hero {
          mobile {
            h1
            p
          }
        }
      }
    }
  }
`

export const HeroMobile = () => {
  const {content} = useStaticQuery(dataQuery)

  return (
    <div className="container pb-2">
      <h1>{content.sections.hero.mobile.h1}</h1>
      <p className={p}>{content.sections.hero.mobile.p}</p>
    </div>
  )
}
