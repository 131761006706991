import React, {useEffect, useState} from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Image from 'gatsby-image'

// styles
import * as heroCSS from './css/hero.module.css'

import {DownloadState} from '../DownloadStates'

import {useGetHeroExtension} from './hooks'

const dataQuery = graphql`
  query {
    content: homepageJson {
      config {
        rapid {
          sections {
            feature
          }
        }
      }
      sections {
        hero {
          desktop {
            h1
          }
        }
      }
    }
  }
`

export default function Hero({extensions}) {
  const {content} = useStaticQuery(dataQuery)
  const extension = useGetHeroExtension(extensions)

  const rapid = {
    sec: content.config.rapid.sections.feature,
    ll1: extension.tag,
  }

  return (
    <div className="container pb-5">
      <h1>{content.sections.hero.desktop.h1}</h1>

      <ShowInFade>
        <div className="row">
          <div className={`${heroCSS.heroExtensionCopySection} col-4`}>
            <h3>{extension.title}</h3>
            <p className={heroCSS.description}>{extension.description_hero}</p>
            <DownloadState ext={extension} rapid={rapid} />
          </div>
          <div className="col-8">
            <Image fluid={extension.image_hero.sharp.fluid} className={`${heroCSS.img} shadow`} alt={extension.title} />
          </div>
        </div>
      </ShowInFade>
    </div>
  )
}

/* Makes smoothie transition between switching hero extensions provided by query params */
function ShowInFade({children}) {
  const [showHero, setShowHero] = useState(heroCSS.hidden)

  useEffect(() => {
    setShowHero(heroCSS.show)
  }, [])

  return <div className={showHero}>{children}</div>
}
