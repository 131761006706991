import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'

export function GTM() {
  return (
    <>
      <GIframe />

      <noscript>
        <GIframe />
      </noscript>
    </>
  )
}

const query = graphql`
  query {
    common: commonJson {
      gtm {
        id
      }
    }
  }
`

function GIframe() {
  const {common} = useStaticQuery(query)

  return (
    <iframe
      src={`https://www.googletagmanager.com/ns.html?id=${common.gtm.id}&type=yahoohp&cat=lpsrcext`}
      height="0"
      width="0"
      style={{display: 'none', visibility: 'hidden'}}
      referrerPolicy="origin"
    />
  )
}
