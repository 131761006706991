import React from 'react'
import Image from 'gatsby-image'

import {getRapid} from '../../services'

import * as ListCSS from './css/list.module.css'

export const ListItem = ({title, description, ctaUrl, ctaLabel, imgFluid, rapid = {}}) => {
  const onClick = () => {
    const rapidInstance = getRapid()

    const tarObj = rapidInstance._normalizeTar(ctaUrl)

    const params = {
      ...tarObj,

      itc: 0,
      cpos: 1,
      elem: 'btn',
      ll1: rapid.ll1,
    }

    rapidInstance.beaconClick(rapid.sec, ctaLabel, rapid.pos, params)
  }

  return (
    <li className="list-group-item py-4 px-0 bg-transparent border-white">
      <div className="media">
        <Image style={{width: '100px'}} fluid={imgFluid} className="align-self-center mr-3" alt={title} />
        <div className="media-body">
          <h4 className={ListCSS.heading}>{title}</h4>
          <p className={ListCSS.description}>{description}</p>
          <a
            onClick={onClick}
            href={ctaUrl}
            className="btn btn-primary rounded-pill"
            role="button"
            target="_blank"
            rel="noreferrer">
            {ctaLabel}
          </a>
        </div>
      </div>
    </li>
  )
}
