import React from 'react'
import {ListGroup, ListItem} from '../MobileList'
import {graphql, useStaticQuery} from 'gatsby'

const dataQuery = graphql`
  query {
    content: homepageJson {
      config {
        rapid {
          sections {
            listing
          }
        }
      }
    }
  }
`

export const MobileExtensions = ({extensions}) => {
  const {content} = useStaticQuery(dataQuery)

  return (
    <div className="container">
      <ListGroup>
        {extensions.map(({title, description, url, ctaLabel, thumbnail, id, tag}, idx) => {
          const rapid = {
            ll1: tag,
            pos: idx + 1,
            sec: content.config.rapid.sections.listing,
          }

          return (
            <ListItem
              rapid={rapid}
              key={id}
              title={title}
              description={description}
              ctaUrl={url}
              ctaLabel={ctaLabel}
              imgFluid={thumbnail.sharp.fluid}
            />
          )
        })}
      </ListGroup>
    </div>
  )
}
