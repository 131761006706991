import React, {useMemo} from 'react'
import {graphql} from 'gatsby'
import {useQueryParam, StringParam} from 'use-query-params'
import BackgroundImage from 'gatsby-background-image'

import '../assets/css/GlobalCSS'

import {getRapid} from '../services/getRapid'

// page level components
import {DesktopLayout, MobileLayout} from '../components/Layout'
import SEO from '../components/SEO'
import {GTM} from '../components/GTM'
import Header from '../components/Header'
import Hero from '../components/Hero'
import Extensions from '../components/Extensions'
import Footer from '../components/Footer'

import {HeroMobile} from '../components/Hero/HeroMobile'
import {MobileExtensions} from '../components/Extensions/MobileExtensions'

import {useExtensions} from '../hooks'

const IndexPage = ({data}) => {
  const extensions = useExtensions(data.allDesktopExtensionsJson.extensions)

  // # RAPID BEGIN
  const [extInParam] = useQueryParam('ext', StringParam)

  useMemo(() => {
    if (typeof window === 'undefined') return

    const rapid = data.homepageJson.config.rapid
    const config = rapid.default

    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

    // we are using css media query to show mobile or desktop layout
    // for now, this is a magic number to decide which one of the rapid spaceid to choose
    // later we will change it based on device?
    config.spaceid = width > 1019 ? rapid.spaceids.desktop : rapid.spaceids.mobile

    config.keys = {
      ...config.keys,

      p_sec: extInParam,
    }

    getRapid(config)
  }, [])
  // # RAPID END

  return (
    <>
      <SEO />

      <DesktopLayout>
        <BackgroundImage fluid={data.heroBgImage.sharp.fluid} backgroundColor={'var(--color-bg-main)'} fadeIn={false}>
          <Header />
          <Hero extensions={extensions} />
        </BackgroundImage>
        <Extensions extensions={extensions} />
      </DesktopLayout>

      <MobileLayout>
        <div style={{background: 'var(--color-bg-main)'}}>
          <Header />
          <HeroMobile />
        </div>
        <MobileExtensions extensions={data.allMobileExtensionsJson.extensions} />
      </MobileLayout>

      <Footer />

      <GTM />
    </>
  )
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    homepageJson {
      config {
        rapid {
          default {
            keys {
              pt
              ver
            }
          }
          spaceids {
            desktop
            mobile
          }
        }
      }
    }

    allDesktopExtensionsJson {
      extensions: nodes {
        id
        tag
        title
        description_hero
        description_card
        ctaLbl
        cssFlags
        browsers {
          chrome
          firefox
          edge
        }
        image_hero {
          sharp: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image_card {
          sharp: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }

    allMobileExtensionsJson {
      extensions: nodes {
        tag
        id
        title
        description
        url
        ctaLabel
        thumbnail {
          sharp: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }

    heroBgImage: file(relativePath: {eq: "Hero/images/hero_background.jpg"}) {
      sharp: childImageSharp {
        fluid(maxWidth: 1680, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
